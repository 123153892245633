// Открыть модальное окно
$('.deposit-btn').click(function(event) {
  event.stopPropagation(); // Предотвратить всплытие события
  $('.topup-pop-up').css({
    'display': 'block',
    'opacity': 0
  }).animate({
    'opacity': 1
  }, 400);
  $('.modal_backwards').css({
    'display': 'block',
    'opacity': 0
  }).animate({
    'opacity': 1
  }, 400);
});

// Скрыть модальное окно при клике вне его
$(document).click(function(event) {
  if (!$(event.target).closest('.topup-pop-up').length && !$(event.target).closest('.deposit-btn').length) {
    $('.topup-pop-up').animate({
      'opacity': 0
    }, 400, function() {
      $(this).css('display', 'none');
    });
    $('.modal_backwards').animate({
      'opacity': 0
    }, 400, function() {
      $(this).css('display', 'none');
    });
  }
});

// Предотвратить закрытие модального окна при клике на самом модальном окне
$('.topup-pop-up').click(function(event) {
  event.stopPropagation();
});



// ------------- account like um -------------------------------_
let dataTitle = "";
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll("form.wppb-user-forms ul li")) {
    // задаємо актрибу "data-title" нашим полям
    document.querySelectorAll("form.wppb-user-forms ul li").forEach((el) => {
      el.classList.contains("wppb-heading")
        ? (dataTitle = el.textContent)
        : null;
      dataTitle != "" ? el.setAttribute("data-title", dataTitle) : null;
    });

    // при наитискані на кастомну кнопку змінювати контент
    document.querySelectorAll(".acc-header button").forEach((el) => {
      el.setAttribute("data-title", el.textContent.trim());

      el.addEventListener("click", () => {
        dataTitle = el.getAttribute("data-title");

        if (dataTitle == "Delete Account" || dataTitle == "Удалить аккаунт") {
          document
            .querySelector("#wppb-edit-user-account")
            .classList.add("hideButton");
        } else {
          document
            .querySelector("#wppb-edit-user-account")
            .classList.remove("hideButton");
        }

        document.querySelectorAll(`li[data-title]`).forEach((e) => {
          e.classList.remove("show");
          e.getAttribute("data-title") == dataTitle
            ? e.classList.add("show")
            : null;
        });

        document.querySelectorAll(".acc-header button").forEach((el) => {
          el.classList.remove("current");
        });
        el.classList.add("current");

        localStorage.setItem("currentAccountTab", dataTitle);
      });
    });

    switch (localStorage.getItem("currentAccountTab")) {
      case "Аккаунт":
        localStorage.setItem("currentAccountTab", "Account");
        break;
      case "Изменить пароль":
        localStorage.setItem("currentAccountTab", "Change Password");
        break;
      case "Удалить аккаунт":
        localStorage.setItem("currentAccountTab", "Delete Account");
        break;
      default:
        break;
    }

    if (window.location.href.includes("/ru/")) {
      switch (localStorage.getItem("currentAccountTab")) {
        case "Account":
          localStorage.setItem("currentAccountTab", "Аккаунт");
          break;
        case "Change Password":
          localStorage.setItem("currentAccountTab", "Изменить пароль");
          break;
        case "Delete Account":
          localStorage.setItem("currentAccountTab", "Удалить аккаунт");
          break;
        default:
          break;
      }
    }

    if (localStorage.getItem("currentAccountTab")) {
      document.querySelector(
        `[data-title="${localStorage.getItem("currentAccountTab")}"]`
      )
        ? document
            .querySelector(
              `[data-title="${localStorage.getItem("currentAccountTab")}"]`
            )
            .click()
        : document.querySelector(".acc-header button")?.click();
    } else {
      document.querySelector(".acc-header button")?.click();
    }
  }
});

// ------------- end account like um -------------------------------_

// delete account
const checkPassQuery = (inputCheck, responseEl) => {
  jQuery.post(
    `${document.location.origin}/wp/wp-admin/admin-ajax.php?action=chekc_old_pass`,
    {
      action: "chekc_old_pass",
      user_id: user_id,
      old_pass: inputCheck.value,
    },
    function (response) {
      if (response == true) {
        responseEl.forEach((el) => {
          el.removeAttribute("disabled");
        });
      } else {
        // console.log("error");
        // console.log(response);

        responseEl.forEach((el) => {
          el.setAttribute("disabled", "");
        });
      }
    }
  );
};

// delete account
document.addEventListener("DOMContentLoaded", function () {
  if (
    document.querySelector(
      ".wppb-heading[data-title='Delete Account'], .wppb-heading[data-title='Удалить аккаунт']"
    )  && !document.querySelector("#delete-acc-pass")
  ) {
    let elDeleteAcc = document.createElement("li");

    elDeleteAcc.classList.add("wppb-form-field");
    elDeleteAcc.classList.add("wppb-delete-account-box");
    elDeleteAcc.classList.add("pbpl-class");

    window.location.href.includes("/ru/")
      ? elDeleteAcc.setAttribute("data-title", "Удалить аккаунт")
      : elDeleteAcc.setAttribute("data-title", "Delete Account");

    elDeleteAcc.innerHTML = `
        <p>Are you sure you want to delete your account? This will erase all of your account data from the site. To delete
        your account enter your password below.</p>
        <label>Password</label>
        <input type="password" name="delete-acc-pass" id="delete-acc-pass" placeholder="Password *">
        <a href="${window.location.origin}/?wppb_user=${user_id}&wppb_action=wppb_delete_user&wppb_nonce=${its_me_mario}" class="button button-white" id="delete-acc" disabled>Delete Account</a>
      `;

    document
      .querySelector(
        ".wppb-heading[data-title='Delete Account'], .wppb-heading[data-title='Удалить аккаунт']"
      )
      .after(elDeleteAcc);
      let timeout; // Добавьте переменную timeout

      document.querySelector("#delete-acc-pass").addEventListener("keyup", (e) => {
        clearTimeout(timeout); // Очистите предыдущий таймер
  
        // Установите новый таймер с задержкой в 300 миллисекунд
        timeout = setTimeout(() => {
          checkPassQuery(
            document.querySelector("#delete-acc-pass"),
            document.querySelectorAll("#delete-acc")
          );
        }, 300);
      });
    }
  document.querySelector("main script[type='text/javascript']")
    ? document.querySelector("main script[type='text/javascript']").remove()
    : null;
});
// end delete account





// change input submit to button submit

let allButton = document.querySelectorAll("input[type='submit']").forEach((el) => {
    let newButton = document.createElement("button");
    newButton.setAttribute("type", "button");
    // newButton.classList.add("um-button");
    // newButton.classList.add("um-button");
    el.classList.forEach((elem) => {
      newButton.classList.add(elem);
    });
    newButton.innerText = el.getAttribute("value");
  
    el.style.cssText =
      "height: 0; width: 0; padding: 0; margin: 0; transition: 0s; display: none;";
  
    newButton.addEventListener("click", () => {
      el.click();
    });
  
    el.parentNode.insertBefore(newButton, el);
    // el.parentNode.append(newButton);
  });
  
  // end change input submit to button submit
  


$('.slider-container').slick({
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
})


$('.question_block').click(function() {
    $(this).toggleClass('active');
    $(this).find('.answer').slideToggle();
});




$('.snapchat').click(function() {
  $('.container_for_little_circle').css('transform', 'rotate(35deg)')
  $('.social_media_promotion .for_circle_block .circle .content').css('opacity', '0')
  $('.social_media_promotion .for_circle_block .circle .snapchat_content').css('opacity', '1')
});
$('.tiktok').click(function() {
  $('.container_for_little_circle').css('transform', 'rotate(0deg)')
  $('.social_media_promotion .for_circle_block .circle .content').css('opacity', '0')
  $('.social_media_promotion .for_circle_block .circle .tiktok_content').css('opacity', '1')
});
$('.inst').click(function() {
  $('.container_for_little_circle').css('transform', 'rotate(-38deg)')
  $('.social_media_promotion .for_circle_block .circle .content').css('opacity', '0')
  $('.social_media_promotion .for_circle_block .circle .inst_content').css('opacity', '1')
});
$('.facebook_little').click(function() {
  $('.container_for_little_circle').css('transform', 'rotate(-70deg)')
  $('.social_media_promotion .for_circle_block .circle .content').css('opacity', '0')
  $('.social_media_promotion .for_circle_block .circle .facebook_content').css('opacity', '1')
});



// function redirect_to_other_lang() {
//   let url = new URL(window.location.href);
//   let current_path = url.pathname;

//   if (current_path.indexOf("/ru/") != -1) {
//     let current_path_without_ru = current_path.substring(
//       current_path.indexOf("/ru/") + 3
//     );
//     window.location.href = current_path_without_ru + "?_amc-currency=USD";
//   } else {
//     let current_path_with_ru = "/ru/" + current_path;
//     current_path_with_ru = current_path_with_ru.replace("//", "/");
//     window.location.href = current_path_with_ru + "?_amc-currency=KZT";
//   }
// }

// // Redirect to the other language with the currency parameter
// // redirect_to_other_lang();

// // Remove the hash from the URL after the page is fully loaded
// window.addEventListener("load", function () {
//   window.history.replaceState({}, document.title, window.location.pathname);
// });

// if (!location.search.includes("currency=")) {
//   if (location.pathname.startsWith("/ru/")) {
//     location.search += (location.search ? "&" : "?") + "_amc-currency=KZT";
//   } else {
//     location.search += (location.search ? "&" : "?") + "_amc-currency=USD";
//   }
// }

// if (window.location.href.indexOf("/ru/") != -1) {
//   $(".js_ru").addClass("current_lang");
// } else {
//   $(".js_eng").addClass("current_lang");
// }

// $(".js_ru").click(function () {
//   if ($(this).hasClass("current_lang")) {
//     // nothing
//   } else {
//     localStorage.setItem("currentLanguage", "ru");
//     redirect_to_other_lang();
//   }
// });

// $(".js_eng").click(function () {
//   if ($(this).hasClass("current_lang")) {
//     // nothing
//   } else {
//     localStorage.setItem("currentLanguage", "eng");
//     redirect_to_other_lang();
//   }
// });

// if (localStorage.getItem("currentLanguage")) {
//   localStorage.getItem("currentLanguage") == "ru" ? document.querySelector(".js_ru").click() : null;
//   localStorage.getItem("currentLanguage") == "eng" ? document.querySelector(".js_eng").click() : null;
// }

// // window.location.search = "";

// document.querySelectorAll("[data-language-current]").forEach((el) => {
//   let language = el.getAttribute("data-language-current");

//   if (language == "ru_RU") {
//     document
//       .querySelectorAll(`[data-language="ru"]`)
//       .forEach((link) => link.classList.add("current_lang"));
//   }

//   if (language == "en_US") {
//     document
//       .querySelectorAll(`[data-language="eng"]`)
//       .forEach((link) => link.classList.add("current_lang"));
//   }
// });

function redirect_to_other_lang(changeTo = "eng") {
  if (changeTo == "eng") {
    document.querySelector('.trp-language-wrap a[title="English"]')
      ? document.querySelector('.trp-language-wrap a[title="English"]').click()
      : null;
  }

  if (changeTo == "ru") {
    document.querySelector('.trp-language-wrap a[title="Russian"]')
      ? document.querySelector('.trp-language-wrap a[title="Russian"]').click()
      : null;
  }
}

// перевіряє чи мова сходиться з валютою
// currentLanguage.toLocaleLowerCase() == "en_us" &&
// currentCurrency.toLocaleLowerCase() != "usd"
//   ? (location.search += (location.search ? "&" : "?") + "_amc-currency=USD")
//   : null;

// currentLanguage.toLocaleLowerCase() == "ru_ru" &&
// currentCurrency.toLocaleLowerCase() != "kzt"
//   ? (location.search += (location.search ? "&" : "?") + "_amc-currency=KZT")
//   : null;

// Remove the hash from the URL after the page is fully loaded
// window.addEventListener("load", () => {
//   window.location.href.includes("_amc-currency")
//     ? window.history.replaceState({}, document.title, window.location.pathname)
//     : null;
// });

window.location.href.indexOf("/ru/") != -1
  ? $(".js_ru").addClass("current_lang")
  : $(".js_eng").addClass("current_lang");

$(".js_ru").click(function () {
  localStorage.setItem("currentLanguageWebwriters", "ru");
  $(this).hasClass("current_lang") ? null : redirect_to_other_lang("ru");
});

$(".js_eng").click(function () {
  localStorage.setItem("currentLanguageWebwriters", "eng");
  $(this).hasClass("current_lang") ? null : redirect_to_other_lang("eng");
});

// if (localStorage.getItem("currentLanguageWebwriters")) {
//   !localStorage.getItem("currentLanguageWebwriters") == "ru" && currentLanguage == "ru_RU"
//     ? document.querySelector(".js_ru").click()
//     : null;

//   !localStorage.getItem("currentLanguageWebwriters") == "eng" && currentLanguage == "en_US"
//   // localStorage.getItem("currentLanguageWebwriters") == "eng"
//     ? document.querySelector(".js_eng").click()
//     : null;
// }


// header currency switcher logic

document.querySelectorAll(".currency-switcher").forEach((el) => {
  let currentCurrency = el.getAttribute("data-current");

  el.querySelectorAll("a").forEach((link) => {
    if (
      link.getAttribute("href").replace("?_amc-currency=", "") ==
      currentCurrency
    ) {
      link.classList.add("current-currency");

      let cloneLi = link.cloneNode(true);

      el.insertAdjacentElement("afterbegin", cloneLi);

      link.remove();
    } else {
      link.classList.remove("current-currency");
    }
  });
});

// end header currency switcher logic


$('.pricing_tabs-title button').click(function() {
  $('.pricing_tabs-title button').removeClass('active');
  $(this).addClass('active');
  let index = $(this).index(); 
  $('.tabs .products_flex').slideUp();
  $('.tabs .products_flex:eq(' + index + ')').slideDown();
});

// .wppb_form_general_message
$(document).ready(function() {
  

  setTimeout(function() {
    $('.acc-header li button').click(function() {
      $('#wppb_form_general_message').slideUp();
    }) 
  }, 1300)
})

$(document).on('click', '#delete-acc', function() {
  localStorage.removeItem("currentAccountTab");
});